import { HStack, Select, Text, VStack } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { formatTime } from "../../utils";

export default function SelectTime({
  startTime,
  setStartTime,
  endTime,
  setEndTime,
}) {
  const fullHours = Array(24)
    .fill()
    .map((_, index) => index + 1);
  const [endTimeArray, setEndTimeArray] = useState(fullHours);
  const step = 1;

  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);

  const handleSelectStartTime = () => {
    if (startTimeRef.current) {
      startTimeRef.current.blur();
    }
  };
  const handleSelectEndTime = () => {
    if (endTimeRef.current) {
      endTimeRef.current.blur();
    }
  };

  return (
    <VStack width="100%" spacing="12px">
      <HStack width="100%" spacing="0px">
        <Select
          width="85%"
          ref={startTimeRef}
          placeholder="시작 시각"
          fontFamily="PRT-Medium"
          color={startTime ? "black" : "lightgray"}
          value={startTime}
          onChange={(event) => {
            handleSelectStartTime();
            const value = event.target.value;
            setStartTime(value);
            if (value) {
              const newArray = [];
              for (let t = parseInt(value) + step; t <= 24; t += step) {
                newArray.push(t);
              }
              setEndTimeArray(newArray);
              if (parseInt(value) >= parseInt(endTime)) {
                setEndTime("");
              }
            } else {
              setEndTimeArray(fullHours);
            }
          }}
        >
          {Array(24)
            .fill()
            .map((_, index) => (
              <option className="option" key={index} value={index}>
                {`${index}시 (${formatTime(index)})`}
              </option>
            ))}
        </Select>
        <Text width="15%" paddingLeft="10px" fontFamily="PRT-SemiBold">
          부터
        </Text>
      </HStack>
      <HStack width="100%" spacing="0px">
        <Select
          width="85%"
          ref={endTimeRef}
          placeholder="종료 시각"
          fontFamily="PRT-Medium"
          color={endTime ? "black" : "lightgray"}
          value={endTime}
          onChange={(event) => {
            handleSelectEndTime();
            setEndTime(event.target.value);
          }}
        >
          {endTimeArray.map((t, index) => (
            <option
              className="option"
              key={index}
              value={t}
            >{`${t}시 (${formatTime(t)})`}</option>
          ))}
        </Select>
        <Text width="15%" paddingLeft="10px" fontFamily="PRT-SemiBold">
          까지
        </Text>
      </HStack>
    </VStack>
  );
}
