import { Box, Skeleton, Text, VStack } from "@chakra-ui/react";

export default function EventTitle({ eventName }) {
  return (
    <VStack width="100%" spacing="0px">
      {eventName !== null ? (
        <Text
          width="100%"
          paddingLeft="5px"
          fontFamily="PRT-SemiBold"
          fontSize="32px"
        >
          {eventName === "" ? "TimePick" : eventName}
        </Text>
      ) : (
        <Box width="100%" paddingLeft="5px" paddingY="7px">
          <Skeleton width="50%" height="32px" />
        </Box>
      )}
    </VStack>
  );
}
