import { createBrowserRouter } from "react-router-dom";
import Root from "./pages/Root";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import Guide from "./pages/Guide";
import Event from "./pages/Event";
import Contact from "./pages/Contact";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "guide",
        element: <Guide />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "event/:id",
        element: <Event />,
      },
    ],
  },
]);

export default router;
