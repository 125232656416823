import { Text, VStack } from "@chakra-ui/react";

export default function DateInfo({ date, isValid }) {
  const month = date.getMonth() + 1;
  const date_ = date.getDate();
  const day = "일월화수목금토"[date.getDay()];
  const isSunday = day === "일";
  const isSaturday = day === "토";
  const fontColor = isValid
    ? isSunday
      ? "red.500"
      : isSaturday
      ? "blue.500"
      : "black"
    : isSunday
    ? "red.100"
    : isSaturday
    ? "blue.100"
    : "gray.300";

  return (
    <VStack width="100%" spacing="0px">
      <Text
        fontFamily="PRT-SemiBold"
        fontSize="13px"
        color={fontColor}
      >{`${month}/${date_}`}</Text>
      <Text fontFamily="PRT-SemiBold" fontSize="14px" color={fontColor}>
        {day}
      </Text>
    </VStack>
  );
}
