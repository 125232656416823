import { Box, Image, Link } from "@chakra-ui/react";
import MenuDrawer from "./MenuDrawer";

export default function Header() {
  return (
    <Box
      width="100%"
      height="96px"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor="white"
    >
      <Link
        href={
          process.env.NODE_ENV === "development"
            ? "http://127.0.0.1:3000"
            : "https://timepick.net"
        }
      >
        <Image
          cursor="pointer"
          width="128px"
          src={`${process.env.PUBLIC_URL}/images/main_logo.png`}
        />
      </Link>
      <Box position="absolute" right="24px">
        <MenuDrawer />
      </Box>
    </Box>
  );
}
