import { Box, HStack, Text, VStack } from "@chakra-ui/react";

export default function AvailableParticipantsModal({
  selectedDateTime,
  selectedParticipants,
  availableParticipants,
  setSelectedCellIndex,
  setAvailableParticipants,
}) {
  let date, time;
  if (selectedDateTime) {
    [date, time] = selectedDateTime.split("\n");
  }

  return (
    <VStack
      margin="32px"
      padding="16px"
      width="90%"
      maxWidth="432px"
      spacing="8px"
      position="fixed"
      bottom="0px"
      backgroundColor="white"
      borderWidth="4px"
      borderRadius="16px"
      borderColor="gray.400"
      onMouseUp={() => {
        setSelectedCellIndex(null);
        setAvailableParticipants(null);
      }}
      onMouseLeave={() => {
        setSelectedCellIndex(null);
        setAvailableParticipants(null);
      }}
    >
      {date && time ? (
        <VStack fontFamily="PRT-SemiBold" fontSize="18px" spacing="0px">
          <Text>{date}</Text>
          <Text>{time}</Text>
        </VStack>
      ) : null}
      <HStack width="100%" spacing="0px">
        <Box width="50%" display="flex" justifyContent="center">
          <Text
            paddingX="4px"
            display="flex"
            justifyContent="center"
            fontFamily="PRT-SemiBold"
            fontSize="18px"
            borderBottomWidth="1px"
            borderColor="black"
          >
            가능
          </Text>
        </Box>
        <Box width="50%" display="flex" justifyContent="center">
          <Text
            paddingX="4px"
            display="flex"
            justifyContent="center"
            fontFamily="PRT-SemiBold"
            fontSize="18px"
            borderBottomWidth="1px"
            borderColor="black"
          >
            불가능
          </Text>
        </Box>
      </HStack>
      <HStack width="100%" spacing="0px">
        <VStack width="50%" spacing="4px">
          {availableParticipants.sort().map((name, index) => (
            <Text key={index} fontFamily="PRT-Medium" fontSize="16px">
              {name}
            </Text>
          ))}
        </VStack>
        <VStack width="50%" spacing="4px">
          {selectedParticipants
            .filter((name) => !availableParticipants.includes(name))
            .sort()
            .map((name, index) => (
              <Text key={index} fontFamily="PRT-Medium" fontSize="16px">
                {name}
              </Text>
            ))}
        </VStack>
      </HStack>
    </VStack>
  );
}
