import { useCallback, useEffect, useRef } from "react";

function isMouseEvent(event) {
  return event instanceof MouseEvent;
}

function isTouchEvent(event) {
  return event instanceof TouchEvent;
}

function convertIndexToString(columnIndex, rowIndex) {
  return `${columnIndex}-${rowIndex}`;
}

function convertStringToIndex(indexString) {
  if (indexString.includes("-")) {
    return indexString.split("-").map(Number);
  }
}

function getCellElement(event) {
  let target;

  if (isTouchEvent(event) && event.touches) {
    const { clientX, clientY } = event.touches[0];
    target = document.elementFromPoint(clientX, clientY);
  } else if (isMouseEvent(event)) {
    target = event.target;
  }

  if (target instanceof HTMLDivElement) {
    if (target?.className.includes("cell")) {
      return target;
    } else if (target?.className.includes("innerCell")) {
      return target.parentElement;
    }
  }
  return null;
}

function getCellIndex(event) {
  let columnIndex = null;
  let rowIndex = null;

  const target = getCellElement(event);

  if (!target) {
    return null;
  }

  if (target.parentElement?.className.includes("column")) {
    const column = target.parentElement;
    const table = column.parentElement;
    const columns = table.getElementsByClassName("column");
    for (let i = 0; i < columns.length; i++) {
      if (columns[i] === column) {
        columnIndex = i;
        break;
      }
    }
    const cells = column.getElementsByClassName("cell");
    for (let j = 0; j < cells.length; j++) {
      if (cells[j] === target) {
        rowIndex = j;
      }
    }
  }

  if (columnIndex === null || rowIndex === null) {
    return null;
  }

  return [columnIndex, rowIndex];
}

export function useDragSelectAvailability(
  weekNumber,
  participantAvailability,
  setParticipantAvailability
) {
  const n = weekNumber;

  const startIndex = useRef("");
  const currentIndex = useRef("");
  const originalTable = useRef([]);
  const mode = useRef(false);

  const tableRef = useRef(null);

  const handlePointerStart = useCallback(
    (e) => {
      const index = getCellIndex(e);

      if (index === null) {
        return;
      }

      const [columnIndex, rowIndex] = index;

      const participantWeekAvailability = participantAvailability.slice(n, n + 7);
      if (participantWeekAvailability[columnIndex][rowIndex] === null) {
        return;
      }

      if (isTouchEvent(e) && e.cancelable) {
        e.preventDefault();
      }

      startIndex.current = convertIndexToString(columnIndex, rowIndex);
      originalTable.current = [...participantWeekAvailability];

      const newTable = [...participantWeekAvailability];
      mode.current = !newTable[columnIndex][rowIndex];
      newTable[columnIndex][rowIndex] = mode.current;
      let newParticipantAvailability = [...participantAvailability];
      newParticipantAvailability.splice(n, 7, ...newTable);
      setParticipantAvailability(newParticipantAvailability);
    },
    [n, participantAvailability, setParticipantAvailability]
  );

  const handlePointerMove = useCallback(
    (e) => {
      if (startIndex.current === "") {
        return;
      }

      if (isMouseEvent(e) && e.buttons !== 1) {
        return;
      }

      const index = getCellIndex(e);

      if (index === null) {
        return;
      }

      const [columnIndex, rowIndex] = index;
      const indexString = convertIndexToString(columnIndex, rowIndex);
      if (indexString === currentIndex.current) {
        return;
      }
      currentIndex.current = indexString;

      const [startColumnIndex, startRowIndex] = convertStringToIndex(
        startIndex.current
      );
      const [minColumn, maxColumn] = [startColumnIndex, columnIndex].sort(
        (a, b) => a - b
      );
      const [minRow, maxRow] = [startRowIndex, rowIndex].sort((a, b) => a - b);

      const participantWeekAvailability = participantAvailability.slice(n, n + 7);
      const newTable = participantWeekAvailability.map((column) => column.slice());
      newTable.forEach((column, i) => {
        column.forEach((_, j) => {
          if (newTable[i][j] !== null) {
            if (i < minColumn || i > maxColumn || j < minRow || j > maxRow) {
              newTable[i][j] = originalTable.current[i][j];
            } else {
              newTable[i][j] = mode.current;
            }
          }
        });
      });
      let newParticipantAvailability = [...participantAvailability];
      newParticipantAvailability.splice(n, 7, ...newTable);
      setParticipantAvailability(newParticipantAvailability);
    },
    [n, participantAvailability, setParticipantAvailability]
  );

  const handlePointerEnd = useCallback((e) => {
    startIndex.current = "";
    if (e.cancelable) {
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    const node = tableRef.current;

    node.addEventListener("touchstart", handlePointerStart);
    node.addEventListener("mousedown", handlePointerStart);
    node.addEventListener("touchmove", handlePointerMove);
    node.addEventListener("mouseover", handlePointerMove);
    node.addEventListener("touchend", handlePointerEnd);
    node.addEventListener("mouseup", handlePointerEnd);
    return () => {
      node.removeEventListener("touchstart", handlePointerStart);
      node.removeEventListener("mousedown", handlePointerStart);
      node.removeEventListener("touchmove", handlePointerMove);
      node.removeEventListener("mouseover", handlePointerMove);
      node.removeEventListener("touchend", handlePointerEnd);
      node.addEventListener("mouseup", handlePointerEnd);
    };
  }, [handlePointerStart, handlePointerMove, handlePointerEnd]);

  return tableRef;
}
