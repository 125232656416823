import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { useDragSelectAvailability } from "../../hooks/useDragSelectAvailability";
import { isValidWeek } from "../../utils";
import DateInfo from "./DateInfo";
import TimeInfo from "./TimeInfo";

export default function ParticipantWeekAvailability({
  weekNumber,
  weekDates,
  data,
  toggleAccordion,
}) {
  const {
    startTime,
    endTime,
    participantAvailability,
    setParticipantAvailability,
  } = data;

  const cellHeight = "20px";

  const tableRef = useDragSelectAvailability(
    weekNumber,
    participantAvailability,
    setParticipantAvailability
  );

  return (
    <AccordionItem width="100%" isDisabled={!isValidWeek(weekDates)}>
      <AccordionButton width="100%" paddingX="0px" onClick={toggleAccordion}>
        <HStack width="100%" spacing="0px">
          <HStack width="95%" spacing="0px">
            <Box width="20%" display="flex" justifyContent="center">
              <AccordionIcon />
            </Box>
            <HStack width="80%" spacing="0px">
              {weekDates.map(([date, isValid], index) => (
                <DateInfo key={index} date={date} isValid={isValid} />
              ))}
            </HStack>
          </HStack>
          <Box width="5%" height="100%" />
        </HStack>
      </AccordionButton>
      <AccordionPanel width="95%" paddingX="0px">
        <HStack width="100%" height="100%" spacing="0px">
          <TimeInfo
            startTime={startTime}
            endTime={endTime}
            onClick={toggleAccordion}
          />
          (
          <HStack className="table" width="80%" spacing="0px" ref={tableRef}>
            {participantAvailability
              .slice(weekNumber, weekNumber + 7)
              .map((column, columnIndex) => (
                <VStack
                  className="column"
                  key={columnIndex}
                  width="100%"
                  spacing="0px"
                >
                  {column.map((cell, rowIndex) => (
                    <Box
                      className="cell"
                      key={rowIndex}
                      width="100%"
                      height={cellHeight}
                      borderTopWidth={
                        rowIndex === 0 ? "2px" : rowIndex % 2 ? "0.5px" : "1px"
                      }
                      borderBottomWidth={
                        rowIndex === column.length - 1
                          ? "2px"
                          : rowIndex % 2
                          ? "1px"
                          : "0.5px"
                      }
                      borderLeftWidth={columnIndex === 0 ? "2px" : "1px"}
                      borderRightWidth={columnIndex === 6 ? "2px" : "1px"}
                      borderColor="black"
                    >
                      <Box
                        className="innerCell"
                        width="100%"
                        height="100%"
                        backgroundColor={
                          cell === null ? "gray" : cell ? "blue.500" : "white"
                        }
                      />
                    </Box>
                  ))}
                </VStack>
              ))}
          </HStack>
          )
        </HStack>
      </AccordionPanel>
    </AccordionItem>
  );
}
