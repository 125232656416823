import { useCallback, useEffect, useRef } from "react";

function isMouseEvent(event) {
  return event instanceof MouseEvent;
}

function isTouchEvent(event) {
  return event instanceof TouchEvent;
}

function convertIndexToString(rowIndex, columnIndex) {
  return `${rowIndex}-${columnIndex}`;
}

function convertStringToIndex(indexString) {
  if (indexString.includes("-")) {
    return indexString.split("-").map(Number);
  }
}

function getCellElement(event) {
  let target;

  if (isTouchEvent(event) && event.touches) {
    const { clientX, clientY } = event.touches[0];
    target = document.elementFromPoint(clientX, clientY);
  } else if (isMouseEvent(event)) {
    target = event.target;
  }
  if (target?.className.includes("date")) {
    return target;
  } else if (target?.className.includes("innerDate")) {
    return target.parentElement;
  }

  return null;
}

function getCellIndex(event) {
  let rowIndex = null;
  let columnIndex = null;

  const target = getCellElement(event);

  if (!target) {
    return null;
  }

  if (target.parentElement?.className.includes("week")) {
    const week = target.parentElement;
    const calendar = week.parentElement;
    const weeks = calendar.getElementsByClassName("week");
    for (let i = 0; i < weeks.length; i++) {
      if (weeks[i] === week) {
        rowIndex = i;
        break;
      }
    }
    const dates = week.getElementsByClassName("date");
    for (let j = 0; j < dates.length; j++) {
      if (dates[j] === target) {
        columnIndex = j;
      }
    }
  }

  if (rowIndex === null || columnIndex === null) {
    return null;
  }

  return [rowIndex, columnIndex];
}

export function useDragSelectCalendar(calendar, setCalendar) {
  const startIndex = useRef("");
  const currentIndex = useRef("");
  const originalCalendar = useRef([]);
  const mode = useRef(false);

  const calendarRef = useRef(null);

  const handlePointerStart = useCallback(
    (e) => {
      const index = getCellIndex(e);

      if (index === null) {
        return;
      }

      const [rowIndex, columnIndex] = index;

      if (
        !(calendar[rowIndex][columnIndex] instanceof Array) ||
        calendar[rowIndex][columnIndex][1] === null
      ) {
        return;
      }

      if (isTouchEvent(e) && e.cancelable) {
        e.preventDefault();
      }

      originalCalendar.current = calendar.map((week) =>
        week instanceof Array
          ? week.map((date) => (date instanceof Array ? [...date] : date))
          : week
      );
      startIndex.current = convertIndexToString(rowIndex, columnIndex);

      const newCalendar = [...calendar];
      mode.current = !newCalendar[rowIndex][columnIndex][1];
      newCalendar[rowIndex][columnIndex][1] = mode.current;
      setCalendar(newCalendar);
    },
    [calendar, setCalendar]
  );

  const handlePointerMove = useCallback(
    (e) => {
      if (startIndex.current === "") {
        return;
      }

      if (isMouseEvent(e) && e.buttons !== 1) {
        return;
      }

      const index = getCellIndex(e);

      if (index === null) {
        return;
      }

      const [rowIndex, columnIndex] = index;
      const indexString = convertIndexToString(rowIndex, columnIndex);
      if (indexString === currentIndex.current) {
        return;
      }
      currentIndex.current = indexString;

      const [startRowIndex, startColumnIndex] = convertStringToIndex(
        startIndex.current
      );
      const [minRow, maxRow] = [startRowIndex, rowIndex].sort((a, b) => a - b);
      const [minColumn, maxColumn] = [startColumnIndex, columnIndex].sort(
        (a, b) => a - b
      );

      const newCalendar = calendar.map((week) =>
        week instanceof Array ? week.slice() : week
      );
      newCalendar.forEach((row, i) => {
        if (row instanceof Array) {
          row.forEach((cell, j) => {
            if (cell instanceof Array && cell[1] !== null) {
              if (i < minRow || i > maxRow || j < minColumn || j > maxColumn) {
                newCalendar[i][j][1] = originalCalendar.current[i][j][1];
              } else {
                newCalendar[i][j][1] = mode.current;
              }
            }
          });
        }
      });
      setCalendar(newCalendar);
    },
    [calendar, setCalendar]
  );

  const handlePointerEnd = useCallback((e) => {
    startIndex.current = "";
    if (e.cancelable) {
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    const node = calendarRef.current;

    node.addEventListener("touchstart", handlePointerStart);
    node.addEventListener("mousedown", handlePointerStart);
    node.addEventListener("touchmove", handlePointerMove);
    node.addEventListener("mouseover", handlePointerMove);
    node.addEventListener("touchend", handlePointerEnd);
    node.addEventListener("mouseup", handlePointerEnd);
    return () => {
      node.removeEventListener("touchstart", handlePointerStart);
      node.removeEventListener("mousedown", handlePointerStart);
      node.removeEventListener("touchmove", handlePointerMove);
      node.removeEventListener("mouseover", handlePointerMove);
      node.removeEventListener("touchend", handlePointerEnd);
      node.addEventListener("mouseup", handlePointerEnd);
    };
  }, [handlePointerStart, handlePointerMove, handlePointerEnd]);

  return calendarRef;
}
