import Cookies from "universal-cookie";
import { v4 as uuid } from "uuid";

const cookies = new Cookies();

function generateUUID() {
  return uuid();
}

export function setUserIdCookie() {
  const userId = generateUUID();
  cookies.set("user_id", userId, {
    path: "/",
    domain:
      process.env.NODE_ENV === "development" ? "127.0.0.1" : ".timepick.net",
  });
}

export function getUserIdFromCookie() {
  return cookies.get("user_id");
}
