import { Box, Skeleton, Text, VStack } from "@chakra-ui/react";

export default function GroupAvailabilityHeader({ participants }) {
  return (
    <VStack width="100%" spacing="0px">
      <Text
        width="100%"
        paddingLeft="5px"
        fontFamily="PRT-SemiBold"
        fontSize="22px"
      >
        모임 전체 시간표
      </Text>
      {participants instanceof Array ? (
        <Text
          width="100%"
          paddingLeft="5px"
          fontFamily="PRT-Medium"
          fontSize="14px"
        >{`참여 인원: ${participants.length}명`}</Text>
      ) : (
        <Box
          width="100%"
          paddingTop="4px"
          paddingLeft="5px"
          display="flex"
          justifyContent="left"
        >
          <Skeleton width="96px" height="14px" />
        </Box>
      )}
    </VStack>
  );
}
