import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Text,
} from "@chakra-ui/react";

export default function DeleteParticipantAlert({
  isOpen,
  onClose,
  onDelete,
  isLoading,
}) {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent width="90%" gap="0px">
          <AlertDialogHeader paddingBottom="4px">
            <Text fontFamily="PRT-SemiBold">일정 삭제</Text>
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text fontFamily="PRT-Medium">
              일정을 삭제하시겠습니까?
              <br /> 삭제된 일정은 복구할 수 없습니다.
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <HStack spacing="10px">
              <Button
                fontFamily="PRT-Light"
                colorScheme="blackAlpha"
                _focus={{ boxShadow: "none" }}
                isLoading={isLoading}
                onClick={() => {
                  onDelete();
                  onClose();
                }}
              >
                삭제
              </Button>
              <Button
                fontFamily="PRT-Light"
                colorScheme="blue"
                isDisabled={isLoading}
                _focus={{ boxShadow: "none" }}
                onClick={onClose}
              >
                취소
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
