import { Text, VStack } from "@chakra-ui/react";
import { formatTime } from "../../utils";

export default function TimeInfo({ startTime, endTime, onClick }) {
  const timeArray = [];
  for (let t = startTime; t <= endTime; t++) {
    timeArray.push(t);
  }

  return (
    <VStack width="20%" paddingRight="8px" spacing="10px" cursor="pointer" onClick={onClick}>
      {timeArray.map((t, index) => (
        <Text
          key={index}
          width="100%"
          height="30px"
          fontFamily="PRT-SemiBold"
          fontSize="12px"
          display="flex"
          justifyContent="right"
          alignItems="center"
        >
          {formatTime(t)}
        </Text>
      ))}
    </VStack>
  );
}
