import { Accordion } from "@chakra-ui/react";
import { generateArray } from "../../utils";
import ParticipantWeekAvailability from "./ParticipantWeekAvailability";
import { useState } from "react";

export default function ParticipantAvailability({ data }) {
  const { dates } = data;

  const [indexes, setIndexes] = useState([0]);


  const toggleAccordion = (i) => {
    if (indexes.includes(i)) {
      setIndexes(indexes.filter((index) => index !== i));
    } else {
      setIndexes([i, ...indexes]);
    }
  };

  const array = generateArray(dates.length);

  return (
    <Accordion width="100%" allowMultiple={true} index={indexes}>
      {array.map((n, i) => (
        <ParticipantWeekAvailability
          key={i}
          weekNumber={n}
          weekDates={dates.slice(n, n + 7)}
          data={data}
          toggleAccordion={() => {
            toggleAccordion(i);
          }}
        />
      ))}
    </Accordion>
  );
}
