import { Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  const [paddingBottom, setPaddingBottom] = useState("24px");

  useEffect(() => {
    let pathname = location.pathname;
    if (pathname === "/" || pathname === "/guide") {
      setPaddingBottom("80px");
    } else {
      setPaddingBottom("24px")
    }
  }, [location]);

  return (
    <VStack
      width="100%"
      paddingX="32px"
      paddingTop="24px"
      paddingBottom={paddingBottom}
      display="flex"
      justifyContent="center"
      alignItems="center"
      spacing="0px"
      backgroundColor="blue.600"
    >
      <Text width="100%" fontFamily="PRT-Medium" fontSize="14px" color="white">
        TimePick
      </Text>
      <Text width="100%" fontFamily="PRT-Light" fontSize="12px" color="white">
        Copyright 2024. All right reserved.
      </Text>
    </VStack>
  );
}
