import { Input, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Calendar from "../componenets/home/Calendar";
import CreateEventButton from "../componenets/home/CreateEventButton";
import SelectTime from "../componenets/home/SelectTime";
import { hasSelectedDate } from "../utils";
import useTopScroll from "../hooks/useTopScroll";

export default function Home() {
  useTopScroll();

  const [eventName, setEventName] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [calendar, setCalendar] = useState(null);
  const [hasValidData, setHasValidData] = useState(false);

  useEffect(() => {
    if (eventName !== "" && startTime && endTime && hasSelectedDate(calendar)) {
      setHasValidData(true);
    } else {
      setHasValidData(false);
    }
  }, [eventName, startTime, endTime, calendar]);

  return (
    <VStack width="100%">
      <VStack width="90%" spacing="24px">
        <VStack width="100%" spacing="8px">
          <Text width="100%" fontFamily="PRT-SemiBold" fontSize="22px">
            모임 이름 입력
          </Text>
          <Input
            className="input"
            type="text"
            placeholder="모임 이름을 입력하세요."
            fontFamily="PRT-Medium"
            maxLength="140"
            spellCheck={false}
            value={eventName}
            onChange={(event) => {
              setEventName(event.target.value.trimStart());
            }}
          />
        </VStack>
        <VStack width="100%" spacing="8px">
          <Text width="100%" fontFamily="PRT-SemiBold" fontSize="22px">
            후보 날짜 선택
          </Text>
          <Calendar calendar={calendar} setCalendar={setCalendar} />
        </VStack>
        <VStack width="100%" spacing="8px">
          <Text width="100%" fontFamily="PRT-SemiBold" fontSize="22px">
            후보 시간대 선택
          </Text>
          <SelectTime
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
          />
        </VStack>
      </VStack>
      <CreateEventButton
        eventName={eventName.trim()}
        startTime={startTime}
        endTime={endTime}
        calendar={calendar}
        hasValidData={hasValidData}
      />
    </VStack>
  );
}
