import { useEffect } from "react";
import { getUserIdFromCookie, setUserIdCookie } from "../cookieService";

export default function useCookie() {
  useEffect(() => {
    const userId = getUserIdFromCookie();
    if (!userId) {
      setUserIdCookie();
    }
  }, []);
}
