import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  HStack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteParticipant,
  readEvent,
  readParticipant,
  updateParticipant,
} from "../api";
import {
  extractAllAvailableTimes,
  fillNull,
  getEventDateAvailability,
  getStringAvailability,
  getParticipantDateAvailability,
  updateGroupAvailability,
} from "../utils";
import GroupAvailability from "../componenets/event/GroupAvailability";
import SkeletonTable from "../componenets/event/SkeletonTable";
import AvailableParticipantsModal from "../componenets/event/AvailableParticipantsModal";
import { Helmet } from "react-helmet-async";
import GroupAvailabilityHeader from "../componenets/event/GroupAvailabilityHeader";
import LinkShareButton from "../componenets/event/LinkShareButton";
import ParticipantAvailability from "../componenets/event/ParticipantAvailability";
import { BsQuestionCircle } from "react-icons/bs";
import LoginModal from "../componenets/event/LoginModal";
import EventTitle from "../componenets/event/EventTitle";
import AllAvailableTimes from "../componenets/event/AllAvailableTimes";
import useTopScroll from "../hooks/useTopScroll";
import KakaoShareButton from "../componenets/event/KakaoShareButton";
import DeleteParticipantAlert from "../componenets/event/DeleteParticipantAlert";
import { RiDeleteBack2Fill } from "react-icons/ri";

export default function Event() {
  useTopScroll();

  const { id } = useParams();
  const navigate = useNavigate();
  const [pk, code] = id.split("-");

  useEffect(() => {
    if (!pk || !code || !/^[0-9]+$/.test(pk) || !/^[a-z0-9]{8}$/.test(code)) {
      navigate("/");
    }
  });

  const [eventName, setEventName] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [dates, setDates] = useState(null);
  const [groupAvailability, setGroupAvailability] = useState(null);
  const [mostAvailableParticipantCount, setMostAvailableParticipantCount] =
    useState(null);
  const [allAvailableTimes, setAllAvailableTimes] = useState([]);

  const [overwrite, setOverwrite] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);

  const [participantPk, setParticipantPk] = useState(null);
  const [participantName, setParticipantName] = useState("");
  const [participantAvailability, setParticipantAvailability] = useState(null);

  const [availabilityToShow, setAvailabilityToShow] = useState(null);

  const [selectedCellIndex, setSelectedCellIndex] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState();
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [availableParticipants, setAvailableParticipants] = useState();

  const {
    isLoading: isLoadingEvent,
    data: event,
    isSuccess,
  } = useQuery(["eventDetail", pk, code], readEvent, {
    enabled: pk && code && /^[0-9]+$/.test(pk) && /^[a-z0-9]{8}$/.test(code),
  });
  const { isLoading: isLoadingParticipant, data: participant } = useQuery(
    ["participant", pk, code, participantPk],
    readParticipant,
    {
      enabled: participantPk !== null,
    }
  );

  const updateParticipantMutation = useMutation(updateParticipant, {
    onSuccess: () => {
      setIsRegistering(false);
    },
  });

  const deleteParticipantMutation = useMutation(deleteParticipant, {
    onSuccess: () => {
      onDeleteAlertClose();
      window.location.reload();
    },
  });

  useEffect(() => {
    if (!isLoadingEvent) {
      if (!isSuccess && process.env.NODE_ENV !== "development") {
        navigate("/");
        return;
      }
      setEventName(event.name);
      setStartTime(event.startTime);
      setEndTime(event.endTime);
      setParticipants(event.participants);
      const [resultDates, resultAvailability] = getEventDateAvailability(
        event.dates.split(","),
        (event.endTime - event.startTime) * 2,
        event.groupAvailability
      );
      setDates(resultDates);
      const timeCount = 2 * (event.endTime - event.startTime);
      const newGroupAvailability = fillNull(resultAvailability, timeCount);
      setGroupAvailability(newGroupAvailability);
      setAvailabilityToShow(newGroupAvailability);
      if (!participantAvailability) {
        setParticipantAvailability(
          newGroupAvailability.map((date) =>
            date.map((time) => (time === null ? null : false))
          )
        );
      }
      let length = event.participants.length;
      let tmpAllAvailableTimes = [];
      while (length > 0) {
        tmpAllAvailableTimes = extractAllAvailableTimes(
          event.startTime,
          resultDates,
          length,
          newGroupAvailability
        );
        if (tmpAllAvailableTimes.length > 0) {
          break;
        }
        length -= 1;
      }
      setAllAvailableTimes(tmpAllAvailableTimes);
      setMostAvailableParticipantCount(length);
    }
  }, [navigate, isSuccess, isLoadingEvent, event, participantAvailability]);

  const toast = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoadingEvent) {
        toast({
          position: "bottom",
          duration: 300,
          render: () => (
            <Box
              width="100%"
              marginBottom="16px"
              display="flex"
              justifyContent="center"
            >
              <Text
                paddingX="16px"
                paddingY="10px"
                borderRadius="8px"
                color="white"
                backgroundColor="blue.500"
                textAlign="center"
                fontFamily="PRT-SemiBold"
                fontSize="16px"
              >
                날짜/시간대 후보가 많아서 로딩이 길어지고 있습니다.
                <br />
                조금만 기다려주시기 바랍니다.
              </Text>
            </Box>
          ),
        });
      }
    }, 2000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (!isLoadingParticipant && participant) {
      setParticipantName(participant.name);
      const timeCount = 2 * (endTime - startTime);
      if (overwrite) {
        if (!participants.includes(participant.name)) {
          participants.push(participant.name);
        }
        updateParticipantMutation.mutate({
          eventPk: pk,
          code,
          participantPk,
          data: {
            availability: getStringAvailability(participantAvailability),
          },
        });
        updateGroupAvailability(
          participant.name,
          participantAvailability,
          groupAvailability,
          setGroupAvailability,
          startTime,
          dates,
          participants.length,
          selectedParticipants,
          setAvailabilityToShow,
          setMostAvailableParticipantCount,
          setAllAvailableTimes
        );
      } else {
        setParticipantAvailability(
          fillNull(
            getParticipantDateAvailability(dates, participant.availability),
            timeCount
          )
        );
      }
      if (!participants.includes(participant.name)) {
        setParticipants([participant.name, ...participants]);
      }
    }
  }, [isLoadingParticipant]);

  const {
    isOpen: isLoginModalOpen,
    onOpen: onLoginModalOpen,
    onClose: onLoginModalClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();

  const { Kakao } = window;
  useEffect(() => {
    Kakao.cleanup();
    Kakao.init("3f40ec66d943790bd10be0e3249a1f8f");
  }, []);

  return (
    <>
      <Helmet>
        <title>{eventName || "타임픽 TimePick"}</title>
        <meta property="og:title" content={eventName || "타임픽 TimePick"} />
        <meta
          property="og:description"
          content="쉽고 빠르게 모임 시간을 정해보세요."
        />
      </Helmet>
      <VStack width="100%" spacing="36px">
        <VStack width="90%" spacing="8px">
          <EventTitle eventName={eventName} />
          <Box width="100%" display="flex" justifyContent="right">
            <HStack spacing="8px">
              <LinkShareButton
                isLoading={isLoadingEvent}
                id={`${pk}-${code}`}
              />
              <KakaoShareButton
                isLoading={isLoadingEvent}
                id={`${pk}-${code}`}
                eventName={eventName}
              />
            </HStack>
          </Box>
        </VStack>
        {participantAvailability ? (
          <VStack width="90%" spacing="8px">
            <VStack width="100%" spacing="0px">
              <HStack width="100%" justifyContent="space-between">
                <Text
                  paddingLeft="5px"
                  fontFamily="PRT-SemiBold"
                  fontSize="22px"
                >
                  내 일정 선택하기{" "}
                  {participantPk && participantName
                    ? `(${participantName})`
                    : null}
                </Text>
                {participantPk ? (
                  <RiDeleteBack2Fill
                    size="26px"
                    color="gray"
                    cursor="pointer"
                    _focus={{ boxShadow: "none" }}
                    onClick={onDeleteAlertOpen}
                  />
                ) : (
                  <HStack
                    spacing="4px"
                    cursor="pointer"
                    onClick={() => {
                      setOverwrite(false);
                      onLoginModalOpen();
                    }}
                  >
                    <BsQuestionCircle size="14px" />
                    <Text fontFamily="PRT-Medium" fontSize="14px">
                      이미 일정을 등록하셨나요?
                    </Text>
                  </HStack>
                )}
              </HStack>
            </VStack>
            <ParticipantAvailability
              data={{
                startTime,
                endTime,
                dates,
                participantName,
                participantAvailability,
                setParticipantAvailability,
                groupAvailability,
                setGroupAvailability,
              }}
            />
            <Button
              marginTop="16px"
              colorScheme="blue"
              fontFamily="PRT-Medium"
              fontSize="16px"
              isLoading={isRegistering || isLoginModalOpen}
              _focus={{ boxShadow: "none" }}
              onClick={() => {
                if (participantPk) {
                  setIsRegistering(true);
                  updateParticipantMutation.mutate({
                    eventPk: pk,
                    code,
                    participantPk,
                    data: {
                      availability: getStringAvailability(
                        participantAvailability
                      ),
                    },
                  });
                  updateGroupAvailability(
                    participant.name,
                    participantAvailability,
                    groupAvailability,
                    setGroupAvailability,
                    startTime,
                    dates,
                    participants.length,
                    selectedParticipants,
                    setAvailabilityToShow,
                    setMostAvailableParticipantCount,
                    setAllAvailableTimes
                  );
                } else {
                  setOverwrite(true);
                  onLoginModalOpen();
                }
              }}
            >
              등록하기
            </Button>
          </VStack>
        ) : null}
        {/* <Divider width="40%" height="2px" backgroundColor="black" />/ */}
        <VStack width="90%" spacing="8px">
          <GroupAvailabilityHeader participants={participants} />
          {groupAvailability !== null ? (
            <GroupAvailability
              startTime={startTime}
              endTime={endTime}
              participants={participants}
              dates={dates}
              availabilityToShow={availabilityToShow}
              setAvailabilityToShow={setAvailabilityToShow}
              groupAvailability={groupAvailability}
              selectedCellIndex={selectedCellIndex}
              setSelectedCellIndex={setSelectedCellIndex}
              setSelectedDateTime={setSelectedDateTime}
              selectedParticipants={selectedParticipants}
              setSelectedParticipants={setSelectedParticipants}
              setAvailableParticipants={setAvailableParticipants}
            />
          ) : (
            <SkeletonTable />
          )}
        </VStack>
        {startTime !== null && dates && participants && groupAvailability ? (
          <AllAvailableTimes
            allAvailableTimes={allAvailableTimes}
            mostAvailableParticipantCount={mostAvailableParticipantCount}
            participantCount={participants.length}
            url={`https://timepick.net/event/${id}`}
          />
        ) : null}
        {availableParticipants ? (
          <AvailableParticipantsModal
            selectedDateTime={selectedDateTime}
            selectedParticipants={selectedParticipants}
            availableParticipants={availableParticipants}
            setSelectedCellIndex={setSelectedCellIndex}
            setAvailableParticipants={setAvailableParticipants}
          />
        ) : null}
      </VStack>
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={onLoginModalClose}
        pk={pk}
        code={code}
        setParticipantPk={setParticipantPk}
      />
      <DeleteParticipantAlert
        isOpen={isDeleteAlertOpen}
        onClose={onDeleteAlertClose}
        onDelete={() => {
          deleteParticipantMutation.mutate({
            eventPk: pk,
            code,
            participantPk,
          });
        }}
        isLoading={deleteParticipantMutation.isLoading}
      />
    </>
  );
}
