import { Box, Button, Text, useToast } from "@chakra-ui/react";
import { MdContentCopy } from "react-icons/md";

export default function LinkShareButton({ isLoading, id }) {
  const url = `https://timepick.net/event/${id}`;
  const toast = useToast();

  return (
    <Button
      leftIcon={<MdContentCopy />}
      colorScheme="blue"
      fontFamily="PRT-Light"
      fontSize="14px"
      _focus={{ boxShadow: "none" }}
      isDisabled={isLoading}
      onClick={() => {
        try {
          navigator.clipboard.writeText(url);
          toast({
            position: "bottom",
            duration: 1600,
            render: () => (
              <Box
                width="100%"
                marginBottom="16px"
                display="flex"
                justifyContent="center"
              >
                <Text
                  paddingX="16px"
                  paddingY="10px"
                  borderRadius="8px"
                  color="white"
                  backgroundColor="blue.500"
                  textAlign="center"
                  fontFamily="PRT-SemiBold"
                  fontSize="16px"
                >
                  링크가 복사되었습니다.
                </Text>
              </Box>
            ),
          });
        } catch (error) {
          toast({
            position: "bottom",
            duration: 1600,
            render: () => (
              <Box
                width="100%"
                marginBottom="16px"
                display="flex"
                justifyContent="center"
              >
                <Text
                  paddingX="16px"
                  paddingY="10px"
                  borderRadius="8px"
                  color="white"
                  backgroundColor="red.400"
                  textAlign="center"
                  fontFamily="PRT-SemiBold"
                  fontSize="16px"
                >
                  링크 복사에 실패하였습니다.
                </Text>
              </Box>
            ),
          });
        }
      }}
    >
      <Text>링크 복사</Text>
    </Button>
  );
}
