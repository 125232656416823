import { Box, HStack, Skeleton, VStack } from "@chakra-ui/react";

export default function SkeletonTable() {
  return (
    <VStack width="100%">
      <Skeleton width="100%" height="58px" />
      <HStack width="100%" spacing="0px">
        <VStack width="20%" height="350px" paddingRight="12px" spacing="100%">
          <Skeleton width="80%" height="12px" />
          <Skeleton width="80%" height="12px" />
          <Skeleton width="80%" height="12px" />
          <Skeleton width="80%" height="12px" />
          <Skeleton width="80%" height="12px" />
        </VStack>
        <Skeleton width="80%" height="320px" />
      </HStack>
      <Skeleton width="100%" height="58px" />
    </VStack>
  );
}
