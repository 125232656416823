import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdMenu } from "react-icons/md";

export default function MenuDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  return (
    <>
      <MdMenu
        size="32px"
        cursor="pointer"
        onClick={() => {
          onOpen();
        }}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton _focus={{ boxShadow: "none" }} />
          <DrawerHeader>
            <Box paddingTop="16px" paddingLeft="32px">
              <Image
                src={`${process.env.PUBLIC_URL}/logo192.png`}
                width="48px"
              />
            </Box>
          </DrawerHeader>
          <DrawerBody>
            <VStack paddingY="12px" spacing="16px">
              <Box
                width="100%"
                cursor="pointer"
                onClick={() => {
                  navigate("/guide");
                  onClose();
                }}
              >
                <Text fontFamily="PRT-SemiBold" fontSize="18px" color="black">
                  TimePick 소개 및 사용법
                </Text>
              </Box>
              <Box
                width="100%"
                cursor="pointer"
                onClick={() => {
                  navigate("/");
                  onClose();
                }}
              >
                <Text fontFamily="PRT-SemiBold" fontSize="18px" color="black">
                  새로운 모임 만들기
                </Text>
              </Box>
              <Box
                width="100%"
                cursor="pointer"
                onClick={() => {
                  navigate("/contact");
                  onClose();
                }}
              >
                <Text fontFamily="PRT-SemiBold" fontSize="18px" color="black">
                  문의하기
                </Text>
              </Box>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
