export function getMonthData(year, month) {
  const dates = [];
  const startDate = new Date(year, month - 1, 1);
  const startDateDay = startDate.getDay();
  const endDate = new Date(year, month, 0);
  const endDateDay = endDate.getDay();

  for (
    let date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    dates.push(new Date(date));
  }

  dates.unshift(...Array(startDateDay).fill(null));
  dates.push(...Array(6 - endDateDay).fill(null));

  const tmpDate = new Date();
  tmpDate.setDate(tmpDate.getDate() - 1);
  const yesterday = tmpDate;

  const data = dates.map((date) =>
    date instanceof Date ? [date, date > yesterday ? false : null] : date
  );
  const result = [`${year}년 ${month}월`];
  for (let n = 0; n < data.length; n += 7) {
    result.push(data.slice(n, n + 7));
  }
  return result;
}

export function hasSelectedDate(calendar) {
  for (let week of calendar) {
    if (week instanceof Array) {
      for (let date of week) {
        if (date instanceof Array && date[1]) {
          return true;
        }
      }
    }
  }
  return false;
}

function formatDate(date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");

  return `${yyyy}-${mm}-${dd}`;
}

export function formatTime(time) {
  if (time < 12) {
    return `오전 ${time}시`;
  } else if (time === 12) {
    return "오후 12시";
  } else if (time < 24) {
    return `오후 ${time - 12}시`;
  } else {
    return "오전 12시";
  }
}

export function extractSelectedDates(calendar) {
  return []
    .concat(
      ...calendar
        .filter((week) => week instanceof Array)
        .map((week) => week.filter((date) => date instanceof Array && date[1]))
        .filter((week) => week.length > 0)
        .map((week) => week.map((date) => formatDate(date[0])))
    )
    .join(",");
}

export function getEventDateAvailability(dates, timeCount, groupAvailability) {
  const dateCount = dates.length;
  const availableParticipants = [];
  for (let i = 0; i < dateCount; i++) {
    const dailyAvailableParticipants = [];
    for (let j = 0; j < timeCount; j++) {
      dailyAvailableParticipants.push([]);
    }
    availableParticipants.push(dailyAvailableParticipants);
  }
  const firstDate = new Date(dates[0]);
  const lastDate = new Date(dates[dates.length - 1]);
  const dayDifference = (lastDate - firstDate) / (1000 * 60 * 60 * 24);

  for (const [participant, participantAvailability] of Object.entries(
    groupAvailability
  )) {
    const participantDailyAvailability = participantAvailability.split(",");
    for (let i = 0; i < dateCount; i++) {
      for (let j = 0; j < timeCount; j++) {
        if (participantDailyAvailability[i][j] === "1") {
          availableParticipants[i][j].push(participant);
        }
      }
    }
  }

  let startDate, endDate;
  if (dayDifference < 7 && firstDate.getDay() > lastDate.getDay()) {
    startDate = new Date(firstDate);
    endDate = new Date(firstDate);
    endDate.setDate(endDate.getDate() + 6);
  } else {
    startDate = new Date(dates[0]);
    startDate.setDate(startDate.getDate() - startDate.getDay());

    endDate = new Date(dates[dates.length - 1]);
    endDate.setDate(endDate.getDate() + (6 - endDate.getDay()));
  }

  const allDates = [];
  const currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    allDates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  const resultDates = [];
  const resultAvailability = [];
  allDates.forEach((date) => {
    const index = dates
      .map((d) => new Date(d))
      .findIndex((d) => d.getTime() === date.getTime());
    if (index !== -1) {
      resultDates.push([date, true]);
      resultAvailability.push(availableParticipants[index]);
    } else {
      resultDates.push([date, false]);
      resultAvailability.push(null);
    }
  });

  return [resultDates, resultAvailability];
}

export function getParticipantDateAvailability(
  resultDates,
  participantAvailability
) {
  const boolAvailability = participantAvailability
    .split(",")
    .map((dailyAvailability) =>
      dailyAvailability.split("").map((char) => char === "1")
    );
  let index = 0;
  const resultAvailability = [];
  resultDates.forEach(([_, isValid]) => {
    if (isValid) {
      resultAvailability.push(boolAvailability[index++]);
    } else {
      resultAvailability.push(null);
    }
  });

  return resultAvailability;
}

export function fillNull(array, n) {
  const nulls = new Array(n).fill(null);
  const result = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== null) {
      result.push(array[i]);
    } else {
      result.push(nulls);
    }
  }
  return result;
}

export function generateArray(n) {
  const result = [];
  for (let m = 0; m < n; m += 7) {
    result.push(m);
  }
  return result;
}

export function isValidWeek(dates) {
  return dates.some((date) => date[1]);
}

function toTimeString(startTime, startIndex, endIndex) {
  const startHour = startTime + Math.floor(startIndex / 2);
  const startMinute = startIndex % 2 ? "30" : "00";
  const endHour = startTime + Math.floor(endIndex / 2) + (endIndex % 2 ? 1 : 0);
  const endMinute = endIndex % 2 ? "00" : "30";
  return `${String(startHour).padStart(2, "0")}:${startMinute} - ${String(
    endHour
  ).padStart(2, "0")}:${endMinute}`;
}

export function extractAllAvailableTimes(
  startTime,
  dates,
  length,
  groupAvailability
) {
  if (length === 0) {
    return [];
  }
  const allAvailableTimes = [];
  dates.forEach(([date, isAvailable], i) => {
    if (isAvailable) {
      const dailyAvailableTimes = [];
      const dailyAvailableParticipants = groupAvailability[i];
      let startIndex = null;
      dailyAvailableParticipants.forEach((timeAvailableParticipants, j) => {
        if (timeAvailableParticipants.length === length) {
          if (startIndex === null) {
            startIndex = j;
          }
        } else {
          if (startIndex !== null) {
            dailyAvailableTimes.push(
              toTimeString(startTime, startIndex, j - 1)
            );
            startIndex = null;
          }
        }
      });
      if (startIndex !== null) {
        dailyAvailableTimes.push(
          toTimeString(
            startTime,
            startIndex,
            dailyAvailableParticipants.length - 1
          )
        );
      }
      if (dailyAvailableTimes.length > 0) {
        allAvailableTimes.push([date, dailyAvailableTimes]);
      }
    }
  });
  return allAvailableTimes;
}

export function updateGroupAvailability(
  participantName,
  participantAvailability,
  groupAvailability,
  setGroupAvailability,
  startTime,
  dates,
  length,
  selectedParticipants,
  setAvailabilityToShow,
  setMostAvailableParticipantCount,
  setAllAvailableTimes
) {
  const newGroupAvailability = groupAvailability;
  participantAvailability.forEach((date, i) => {
    date.forEach((isAvailable, j) => {
      if (isAvailable !== null) {
        if (isAvailable) {
          if (!groupAvailability[i][j].includes(participantName)) {
            newGroupAvailability[i][j].push(participantName);
          }
        } else {
          if (groupAvailability[i][j].includes(participantName)) {
            newGroupAvailability[i][j] = newGroupAvailability[i][j].filter(
              (name) => name !== participantName
            );
          }
        }
      }
    });
  });
  setGroupAvailability(newGroupAvailability);
  const newAvailabilityToShow = newGroupAvailability.map((dateAvailability) =>
    dateAvailability.map((availableParticipants) =>
      availableParticipants instanceof Array
        ? availableParticipants.filter((participant) =>
            selectedParticipants.includes(participant)
          )
        : null
    )
  );
  setAvailabilityToShow(newAvailabilityToShow);
  let tmpAllAvailableTimes = [];
  while (length > 0) {
    tmpAllAvailableTimes = extractAllAvailableTimes(
      startTime,
      dates,
      length,
      newGroupAvailability
    );
    if (tmpAllAvailableTimes.length > 0) {
      break;
    }
    length -= 1;
  }
  setAllAvailableTimes(tmpAllAvailableTimes);
  setMostAvailableParticipantCount(length);
}

export function getSelectedDateTime(
  n,
  columnIndex,
  rowIndex,
  dates,
  startTime
) {
  const date = dates[n + columnIndex][0];
  const day = "일월화수목금토"[date.getDay()];
  const time = toTimeString(startTime, rowIndex, rowIndex);
  return `${date.getMonth() + 1}/${date.getDate()} (${day})\n${time}`;
}

export function getStringAvailability(participantAvailability) {
  const boolAvailability = participantAvailability.filter(
    (column) => !column.includes(null)
  );
  return boolAvailability
    .map((innerArray) =>
      innerArray.map((value) => (value ? "1" : "0")).join("")
    )
    .join(",");
}
