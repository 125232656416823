import Cookie from "js-cookie";
import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000/api/"
      : "https://backend.timepick.net/api/",
  withCredentials: true,
});

export async function createEvent({ data }) {
  const response = await instance.post("event/", data, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
  return response.data;
}

export async function readEvent({ queryKey }) {
  const pk = queryKey[1];
  const code = queryKey[2];
  const response = await instance.get(`event/${pk}-${code}/`);
  return response.data;
}

export async function login({ pk, code, data }) {
  const response = await instance.post(`event/${pk}-${code}/login/`, data, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
  return response.data;
}

export async function readParticipant({ queryKey }) {
  const eventPk = queryKey[1];
  const code = queryKey[2];
  const participantPk = queryKey[3];
  const response = await instance.get(
    `event/${eventPk}-${code}/participant/${participantPk}/`
  );
  return response.data;
}

export async function updateParticipant({
  eventPk,
  code,
  participantPk,
  data,
}) {
  await instance.put(
    `event/${eventPk}-${code}/participant/${participantPk}/`,
    data,
    {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    }
  );
}

export async function deleteParticipant({ eventPk, code, participantPk }) {
  await instance.delete(
    `event/${eventPk}-${code}/participant/${participantPk}/`,
    {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    }
  );
}

export async function createContact(data) {
  instance.post(`contact/`, data, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
}
