import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { generateArray, getSelectedDateTime, isValidWeek } from "../../utils";
import DateInfo from "./DateInfo";
import TimeInfo from "./TimeInfo";

export default function GroupAvailability({
  startTime,
  endTime,
  participants,
  dates,
  availabilityToShow,
  setAvailabilityToShow,
  groupAvailability,
  selectedCellIndex,
  setSelectedCellIndex,
  setSelectedDateTime,
  selectedParticipants,
  setSelectedParticipants,
  setAvailableParticipants,
}) {
  const cellHeight = "20px";

  const array = generateArray(dates.length);

  // const [selectedCellIndex, setSelectedCellIndex] = useState(null);

  const [isAllButtonSelected, setIsAllButtonSelected] = useState(true);

  const [indexes, setIndexes] = useState([0]);
  const [isToggleBlocked, setIsToggleBlocked] = useState(false);

  const toggleAccordion = (i) => {
    if (isToggleBlocked) {
      return;
    }
    if (indexes.includes(i)) {
      setIndexes(indexes.filter((index) => index !== i));
    } else {
      setIndexes([i, ...indexes]);
    }
  };

  useEffect(() => {
    setSelectedParticipants(participants);
    setIsAllButtonSelected(true);
  }, [participants]);

  useEffect(() => {
    const newAvailability = groupAvailability.map((dateAvailability) =>
      dateAvailability.map((availableParticipants) =>
        availableParticipants instanceof Array
          ? availableParticipants.filter((participant) =>
              selectedParticipants.includes(participant)
            )
          : null
      )
    );
    setAvailabilityToShow(newAvailability);
    setSelectedCellIndex(null);
    setAvailableParticipants(null);
  }, [groupAvailability, selectedParticipants]);

  const [isOpenBlocked, setIsOpenBlocked] = useState(false);
  const [isCloseBlocked, setIsCloseBlocked] = useState(false);

  const onPress = (n, columnIndex, rowIndex, cell) => {
    if (isOpenBlocked) {
      return;
    }
    if (participants.length > 0 && cell !== null) {
      setSelectedDateTime(
        getSelectedDateTime(n, columnIndex, rowIndex, dates, startTime)
      );
      setSelectedCellIndex(`${n}-${columnIndex}-${rowIndex}`);
      setAvailableParticipants(cell);
    }
  };

  const onLeave = (n, columnIndex, rowIndex, cell) => {
    if (isOpenBlocked) {
      setIsOpenBlocked(true);
      setTimeout(() => {
        setIsOpenBlocked(false);
      }, 500);
    }
    setIsToggleBlocked(true);
    setTimeout(() => {
      setIsToggleBlocked(false);
    }, 500);
    if (
      participants.length > 0 &&
      cell !== null &&
      selectedCellIndex === `${n}-${columnIndex}-${rowIndex}`
    ) {
      setSelectedCellIndex(null);
      setAvailableParticipants(null);
    }
  };

  return (
    <VStack width="100%" spacing="0px">
      {participants.length > 0 ? (
        <VStack width="100%" spacing="8px">
          <HStack width="100%">
            <Button
              height="30px"
              paddingX="8px"
              colorScheme="blue"
              variant={isAllButtonSelected ? "solid" : "outline"}
              onClick={() => {
                setIsAllButtonSelected(true);
                setSelectedParticipants(participants);
              }}
            >
              <Text height="16px" fontFamily="PRT-Light" fontSize="14px">
                모임 전체
              </Text>
            </Button>
            <Text fontFamily="PRT-Medium" fontSize="12px">
              *각 시간대를 꾹 누르면 되는 사람을 볼 수 있어요.
            </Text>
          </HStack>
          <Box width="100%">
            <span>
              {participants.sort().map((participant, index) => (
                <Button
                  key={index}
                  height="30px"
                  paddingX="8px"
                  marginRight="8px"
                  marginBottom="8px"
                  colorScheme="blue"
                  variant={
                    !isAllButtonSelected &&
                    selectedParticipants.includes(participant)
                      ? "solid"
                      : "outline"
                  }
                  onClick={() => {
                    if (isAllButtonSelected) {
                      setIsAllButtonSelected(false);
                      setSelectedParticipants([participant]);
                    } else {
                      if (selectedParticipants.includes(participant)) {
                        const newSelectedParticipants =
                          selectedParticipants.filter(
                            (tmpParticipant) => tmpParticipant !== participant
                          );
                        if (newSelectedParticipants.length === 0) {
                          setSelectedParticipants(participants);
                          setIsAllButtonSelected(true);
                        } else {
                          setSelectedParticipants(newSelectedParticipants);
                        }
                      } else {
                        const newSelectedParticipants = [
                          participant,
                          ...selectedParticipants,
                        ];
                        setSelectedParticipants(newSelectedParticipants);
                      }
                    }
                  }}
                >
                  <Text height="16px" fontFamily="PRT-Light" fontSize="14px">
                    {participant}
                  </Text>
                </Button>
              ))}
            </span>
          </Box>
        </VStack>
      ) : null}
      <Accordion width="100%" allowMultiple={true} index={indexes}>
        {array.map((n, i) => (
          <AccordionItem
            width="100%"
            key={i}
            isDisabled={!isValidWeek(dates.slice(n, n + 7))}
          >
            <AccordionButton
              width="100%"
              paddingX="0px"
              onClick={() => {
                toggleAccordion(i);
              }}
            >
              <HStack width="100%" spacing="0px">
                <HStack width="95%" spacing="0px">
                  <Box width="20%" display="flex" justifyContent="center">
                    <AccordionIcon />
                  </Box>
                  <HStack width="80%" spacing="0px">
                    {dates.slice(n, n + 7).map(([date, isValid], j) => (
                      <DateInfo key={j} date={date} isValid={isValid} />
                    ))}
                  </HStack>
                </HStack>
                <Box width="5%" height="100%" />
              </HStack>
            </AccordionButton>
            <AccordionPanel width="95%" paddingX="0px">
              <HStack width="100%" height="100%" spacing="0px">
                <TimeInfo
                  startTime={startTime}
                  endTime={endTime}
                  onClick={() => {
                    toggleAccordion(i);
                  }}
                />
                <HStack width="80%" spacing="0px">
                  {availabilityToShow
                    .slice(n, n + 7)
                    .map((column, columnIndex) => (
                      <VStack width="100%" key={columnIndex} spacing="0px">
                        {column.map((cell, rowIndex) => (
                          <Box
                            key={rowIndex}
                            width="100%"
                            height={cellHeight}
                            borderTopWidth={
                              rowIndex === 0
                                ? "2px"
                                : rowIndex % 2
                                ? "0.5px"
                                : "1px"
                            }
                            borderBottomWidth={
                              rowIndex === column.length - 1
                                ? "2px"
                                : rowIndex % 2
                                ? "1px"
                                : "0.5px"
                            }
                            borderLeftWidth={columnIndex === 0 ? "2px" : "1px"}
                            borderRightWidth={columnIndex === 6 ? "2px" : "1px"}
                            borderColor={"black"}
                            onTouchStart={() => {
                              onPress(n, columnIndex, rowIndex, cell);
                            }}
                            onMouseDown={() => {
                              setIsCloseBlocked(true);
                              setTimeout(() => {
                                setIsCloseBlocked(false);
                              }, 200);
                              onPress(n, columnIndex, rowIndex, cell);
                            }}
                            onTouchEnd={() => {
                              onLeave(n, columnIndex, rowIndex, cell);
                            }}
                            onMouseUp={() => {
                              onLeave(n, columnIndex, rowIndex, cell);
                            }}
                            onMouseLeave={() => {
                              if (isCloseBlocked) {
                                return;
                              }
                              onLeave(n, columnIndex, rowIndex, cell);
                            }}
                          >
                            <Box
                              width="100%"
                              height="100%"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              backgroundColor={
                                participants.length === 0 || cell === null
                                  ? "gray"
                                  : selectedCellIndex ===
                                    `${n}-${columnIndex}-${rowIndex}`
                                  ? "facebook.800"
                                  : cell?.length === selectedParticipants.length
                                  ? "messenger.600"
                                  : "blue.500"
                              }
                              opacity={
                                selectedCellIndex ===
                                  `${n}-${columnIndex}-${rowIndex}` ||
                                cell === null ||
                                selectedParticipants.length === 0
                                  ? 1
                                  : cell.length / selectedParticipants.length
                              }
                            />
                          </Box>
                        ))}
                      </VStack>
                    ))}
                </HStack>
              </HStack>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </VStack>
  );
}
